$html-font-size: 16px;
$color-bg: #0f0f0f;
$color-white: #fff;
$color-black: #000;
$color-red: #ca0401;

@font-face {
	font-family: 'Strenuous Bl';
	src: url('../font/StrenuousBl-Regular.woff2') format('woff2'),
	url('../font/StrenuousBl-Regular.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@import "../../node_modules/magnific-popup/src/css/main";
@import "framework/base";
@import "framework/common";
@import "framework/functions";
@import "framework/grid";

html {
	/*background: $color-bg url('../img/45-degree-fabric-dark.png') repeat;*/
	background: #000;
	color: $color-white;
	font-family: 'Strenuous Bl';
	font-size: $html-font-size;
	font-weight: normal;
	line-height: 1;
}

a {
	color: $color-white;
	@include hover-focus {
		color: $color-white;
	}
}

.btn {
	background: $color-red;
	border-radius: rem(20px);
	color: $color-white;
	display: inline-block;
	font-size: rem(14px);
	letter-spacing: letter-spacing(25);
	opacity: 1;
	padding: rem(8px) rem(12px);
	text-indent: text-indent(25);
	vertical-align: middle;

	@include hover-focus {
		opacity: 0.8;
	}
}

.red {
	color: $color-red;
}

.desktop-video {
	@include phone-down {
		display: none;
	}
}

.mobile-video {
	display: none;

	@include phone-down {
		display: block;
	}
}

header {
	padding: rem(45px) 0 rem(10px) 0;
	text-align: center;

	@include phone-down {
		padding: rem(30px) 0;
	}

	.container {
		> h1 {
			margin: 0;
			padding-left: rem(30px);

			@include phone-down {
				padding-left: rem(15px);
			}

			> img {
				margin: 0 auto;
				width: rem(625px);
			}
		}

		> p {
			font-size: rem(18px);
			letter-spacing: letter-spacing(25);
			line-height: rem(26px, 18px);
			margin: rem(-10px) auto rem(20px) auto;
			max-width: rem(720px);
			text-indent: text-indent(25);

			br {
				display: none;

				@include phone-down {
					display: block;
				}
			}

			@include phone-down {
				font-size: rem(15px);
				line-height: rem(23px, 15px);
				/*margin-bottom: rem(30px);*/
				margin-bottom: rem(10px);
				margin-top: rem(-5px);
			}

			span {

				display: block;


			}
		}
	}

}

main {
	#connect {
		text-align: center;

		.container {
			> h2 {
				color: $color-red;
				font-size: rem(26px);
				font-weight: normal;
				letter-spacing: letter-spacing(25);
				line-height: 1;
				margin: 0 0 rem(20px) 0;
				text-indent: text-indent(25);

				@include phone-down {
					font-size: rem(24px);
				}
			}

			> .btns {
				@include phone-down {
					display: block;
					margin: 0 auto;
					width: rem(215px);
				}

				> a {
					display: inline-block;
					margin: 0 auto;
					max-width: rem(250px);
					opacity: 1;
					padding: rem(5px);
					vertical-align: middle;

					@include hover-focus {
						opacity: 0.8;
					}

					@include tablet-down {
						padding: rem(5px) 0;
					}
				}
			}

			> .legal {
				font-size: rem(12px);
				margin: rem(20px) 0;
			}

			> .btn {
				@include tablet-down {
					width: rem(215px);
				}
			}
		}
	}

	#videos {
		padding: rem(35px) 0 0 0;
		text-align: center;

		.container {
			> h2 {
				color: $color-red;
				font-size: rem(18px);
				letter-spacing: letter-spacing(50);
				line-height: 1;
				margin: 0 0 rem(15px) 0;
				text-indent: text-indent(50);
			}

			.video-cols {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0 rem(-25px);

				@include phone-down {
					display: block;
				}

				> div {
					flex: 0 0 50%;
					padding: 0 rem(12.5px);

					> video {
						margin-bottom: rem(20px);
					}

					> p {
						font-size: rem(18px);
						letter-spacing: letter-spacing(50);
						line-height: 1;
						margin-bottom: rem(25px);
						text-indent: text-indent(50);
					}

					.btn {
						display: inline-block;
						margin: 0 auto;
						min-width: rem(170px);
						vertical-align: middle;

						+ .btn {
							margin-left: rem(14px);
						}

						@include phone-down {
							display: block;
							margin: 0 auto;
							width: rem(215px);

							+ .btn {
								margin-left: auto;
								margin-top: rem(10px);
							}
						}
					}

					+ div {
						@include phone-down {
							margin-top: rem(35px);
						}
					}

				}
			}
		}
	}

	#merch {
		padding-top: rem(50px);
		text-align: center;

		img {
			margin: 0 auto rem(20px) auto;
			width: rem(350px);
		}
	}
}

footer {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	line-height: 1;
	padding: rem(40px) 0 rem(30px) 0;
	text-align: center;

	@include phone-down {
		padding: rem(40px) 0 rem(20px) 0;
	}

	.socials {
		list-style: none;
		margin: 0 0 rem(40px) 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(20px);
			line-height: 1;
			vertical-align: middle;

			+ li {
				margin-left: rem(10px);
			}

			> a {
				color: $color-white;
				display: inline-block;
				vertical-align: middle;

				@include hover-focus {
					color: $color-red;
				}
			}
		}
	}

	.copyright {
		text-transform: uppercase;

		> a {
			display: inline-block;
			margin-bottom: rem(10px);
			vertical-align: middle;

			> svg {
				height: auto;
				width: rem(42px);

				path {
					fill: $color-white;
				}
			}
		}

		> p {
			font-size: rem(8px);
			letter-spacing: letter-spacing(200);
			line-height: line-height(14px, 8px);
			margin: 0;
			text-indent: text-indent(200);

			> a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.popup {
	background: $color-black;
	color: $color-white;
	margin: 20px auto;
	max-width: 500px;
	padding: 20px;
	position: relative;
	text-align: center;
	width: auto;

	form {
		label {
			display: block;
			font-weight: normal;
			line-height: 1;
			margin-bottom: rem(15px);
		}

		input {
			-webkit-appearance: none;
			background: none;
			border: 2px solid $color-white;
			color: $color-white;
			display: block;
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			line-height: 1;
			margin-bottom: rem(15px);
			padding: rem(5px);
			width: 100%;

			&:focus {
				border-color: $color-red;
				outline: 0;
			}
		}

		button {
			-webkit-appearance: none;
			border: 0;
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			line-height: 1;
		}
	}

	.mfp-close {
		color: $color-white;
		opacity: 1;
	}
}