@mixin hover-focus {

	&:hover,
	&:focus {

		@content;
	}
}

/* General */

* {
	box-sizing: border-box;
}

html {
	position: relative;
	min-height: 100%;
}

body {
	margin: 0;
	padding: 0;
}

a,
button {
	text-decoration: none;
	-webkit-transition: box-shadow 250ms linear, color 250ms linear, background-color 250ms linear, border-width 250ms linear, border-color 250ms linear, opacity 250ms linear;
	transition: box-shadow 250ms linear, color 250ms linear, background-color 250ms linear, border-width 250ms linear, border-color 250ms linear, opacity 250ms linear;

	&:hover,
	&:focus {
		outline: 0;
		text-decoration: none;
	}
}

button,
input[type="submit"] {
	-webkit-transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;
	transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;

	&:hover,
	&:focus {
		outline: 0;
		text-decoration: none;
		cursor: pointer;
	}
}

p {
	margin: 0 0 10px 0;
}

/* Media */
img {
	display: block;
	max-width: 100%;
	height: auto;
}

iframe,
object,
embed,
video {
	display: block;
	max-width: 100%;
	border: 0;
}

.embed-container {
	overflow: hidden;
	position: relative;
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* Helpers */
.sr-only {
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

.sr-only-focusable:focus {
	overflow: visible;
	clip: auto;
	position: static;
	width: auto;
	height: auto;
	margin: 0
}

.clearfix {

	&::after {
		display: table;
		clear: both;
		content: " ";
	}
}
